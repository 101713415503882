import { EInsufficientFunds } from "@/types";

const useGameModal = () => {
	const { data: appInit } = useAppInitData();
	const { open: openPresetPackage } = usePresetPackageController();

	const { open, close } = useTaoModals();
	const { isSweepStakes } = useSwitchMode();
	const { winnings } = useBalance();
	const isGuest = useIsGuest();
	const { isFinOperationAvailable } = useCashHelper();
	const { showPwaModalAfterGame } = usePWA();

	const route = useRoute();
	const hasPromoOffer = computed(() => !!appInit.value?.bannerPresetPackage);

	const insufficientFundsModalType = computed(() => {
		if (!isSweepStakes.value) {
			return hasPromoOffer.value ? EInsufficientFunds.TOURNAMENT_WITH_OFFER : EInsufficientFunds.NO_OFFER;
		}

		if (winnings.value) {
			return EInsufficientFunds.SWEEPSTAKES_WITH_WINNINGS;
		}

		return hasPromoOffer.value ? EInsufficientFunds.SWEEPSTAKES_WITH_OFFER : EInsufficientFunds.NO_OFFER;
	});

	const { data: game, execute: getGame } = useAsyncFetch({
		path: "/rest/game/{id}{mode}/",
		method: "get",
		fetchOptions: {
			path: { id: route.query.game as string, mode: !isGuest.value && route.query.isMoney ? "/play" : "/" }
		},
		options: {
			transform: (data) => data?.[0],
			immediate: false
		}
	});

	const openCashModal = () => {
		window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
		if (!isFinOperationAvailable.value) {
			return;
		}
		openPresetPackage();
	};

	const openInsufficientFundsModal = () => {
		const transactions = window?.$cash?.$store?.getters?.["history/transactions"] as Array<Record<string, string>>;
		const transactionOut = transactions.find((t) => t?.type === "out" && t?.status === "pending");

		if (transactionOut && winnings.value < 1 && isSweepStakes.value) {
			open("LazyOModalInsufficientWithdrawal", {
				amount: numberFormat(+transactionOut.amount),
				id: +transactionOut.id
			});
		} else {
			open("LazyOModalInsufficientFunds", { modalType: insufficientFundsModalType.value });
		}

		dispatchGAEvent({
			type: isSweepStakes.value ? "winnings" : "coins",
			event: "open_popup",
			formName: "empty_balance",
			location: "gameplay"
		});
	};

	const closeModal = async () => {
		await navigateTo(route.path, { replace: true });

		if (showPwaModalAfterGame.value) {
			open("LazyOModalPwaApps");
		}

		close("LazyOModalGame");
		close("LazyOModalMoneyBox");
	};

	const registerGameMessages = (event: MessageEvent) => {
		if (event.data.cash?.popups?.show) {
			openInsufficientFundsModal();
		}

		if (event.data.cash?.popups?.create) {
			const eventSource = event.source as typeof window.parent;
			eventSource.postMessage({ cash: { popups: { created: true } } }, "*");
		}

		if (event.data.cash?.open) {
			openCashModal();
		}

		if (event.data.game?.close) {
			closeModal();
		}
	};

	return {
		game,
		openCashModal,
		getGame,
		registerGameMessages,
		closeModal
	};
};
export default useGameModal;
